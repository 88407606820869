import C from 'constants/Constants';
import { useUtils } from 'utils/utils';
import { usePhoneUtils } from 'utils/phone/phone';
import { usePageUtils } from 'utils/page/page';

import { useDispatch } from 'react-redux';
import {
	updateButtonLoading,
	updateOverlay,
	setAccessToken,
	setVcJwtToken,
	updateBcoResults,
	setApplicationId,
	updateSanc,
	updateUid,
	updateAmount,
	updateRuntime,
	updateCondition,
	updateCapsUploadDone,
	updateWebIdStarted,
	updateWebIdFinished,
	updateIsRequestFromCaps,
	updateSkipLegi,
	updateAppLoading,
	setRequestFromCrossSelling
} from 'store/App/actions';
import { setAmount, setRuntime, setPaymentType } from 'store/Investment/actions';
import { setExternalCredits } from 'store/ExternalCredit/actions';
import { setAllowAdvertising } from 'store/Consent/actions';

import { setSalutation, setFirstName, setLastName } from 'store/Name/actions';
import { setBirthdate } from 'store/Birthdate/actions';
import { setEmail } from 'store/Email/actions';
import { setCountryCode, setMobileNumber, setEncryptedMobileNumber } from 'store/MobileNumber/actions';
import { setReferenceIban, setExistingOldIban } from 'store/BankAccount/actions';
import { setNationality } from 'store/Nationality/actions';
import { setMaritalStatus, setNumberOfAdults } from 'store/MaritalStatus/actions';
import {
	setOccupation,
	setOccupationMonth,
	setOccupationYear,
	setOccupationLimited,
	setOccupationLimitedMonth,
	setOccupationLimitedYear
} from 'store/Occupation/actions';
import { setStreet, setNumber, setPostcode, setCity, setState } from 'store/Address/actions';
import { onUpdateIncome, onUpdateAdditionalIncome } from 'store/FinancialStatus/actions';
import { onUpdateHasSecondApplicant } from 'store/SecondApplicant/actions';
import { setRequestedLimit } from 'store/BestCard/actions';

export const useApiUtils = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { splitMobileNumberAndCountryCode, addSpaceAfterCountryCode } = usePhoneUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();

	const baseUrl = '/api';

	const getApiBaseURL = () => {
		if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_API_URL) {
			return process.env.REACT_APP_API_URL;
		}
		return baseUrl;
	};

	const startApiCall = () => {
		dispatch(updateButtonLoading(true));
		dispatch(updateOverlay(true));
	};

	const stopApiCall = () => {
		dispatch(updateButtonLoading(false));
		dispatch(updateOverlay(false));
	};

	const checkUnauthorized = (statusCode) => {
		if (statusCode === 401) {
			console.warn('Unauthorized: redirect to /login');
			goToPage(currentFlow + C.ROUTES.LOGIN, '?redirect=unauthorized');
			stopApiCall();
			dispatch(updateAppLoading('none'));
			return true;
		}
		return false;
	};

	const addUserDataToStore = (data) => {
		try {
			if (data) {
				dispatch(setAccessToken(data));

				if (data.vcJwtToken) {
					dispatch(setVcJwtToken(data.vcJwtToken));
				}

				if (data.scbAntragId) {
					dispatch(updateBcoResults({ scbAntragId: data.scbAntragId }));
				}

				if (data.applicationId) {
					dispatch(setApplicationId(data.applicationId));
				}

				if (data.sanc) {
					dispatch(updateSanc(data.sanc));
				}

				if (data.uid) {
					dispatch(updateUid(data.uid));
				}

				if (data.requestFromCrossSelling) {
					dispatch(setRequestFromCrossSelling(data.requestFromCrossSelling));
				}

				if (data.allowAdvertising) {
					dispatch(setAllowAdvertising(data.allowAdvertising));
				}

				if (data.salutation) {
					dispatch(setSalutation(data.salutation));
				}
				if (data.firstname) {
					dispatch(setFirstName(data.firstname));
				}
				if (data.lastname) {
					dispatch(setLastName(data.lastname));
				}

				if (data.dateOfBirth) {
					dispatch(setBirthdate(data.dateOfBirth));
				}

				if (data.email) {
					dispatch(setEmail(data.email));
				}

				if (data.mobilePhone) {
					let mobilePhoneArray = splitMobileNumberAndCountryCode(data.mobilePhone);
					dispatch(setCountryCode(mobilePhoneArray[0]));
					dispatch(setMobileNumber(mobilePhoneArray[1]));
				}

				if (data.encryptedMobileNumber) {
					dispatch(setEncryptedMobileNumber(addSpaceAfterCountryCode(data.encryptedMobileNumber)));
				}

				if (data.nationality) {
					dispatch(setNationality(data.nationality));
				}

				if (data.address) {
					dispatch(setStreet(data.address.street));
					dispatch(setNumber(data.address.streetNr));
					dispatch(setPostcode(data.address.zip));
					dispatch(setCity(data.address.city));
					if (data.address.state) {
						dispatch(setState(data.address.state));
					}
				}

				if (data.marital) {
					dispatch(setMaritalStatus(data.marital.maritalStatus));
					dispatch(setNumberOfAdults(data.marital.numOfAdults));
				}

				if (data.jobIndicator) {
					dispatch(setOccupation(data.jobIndicator.occupation));
					if (data.jobIndicator.occupationMonth && data.jobIndicator.occupationYear) {
						dispatch(setOccupationMonth(data.jobIndicator.occupationMonth));
						dispatch(setOccupationYear(data.jobIndicator.occupationYear));
					}
					if (data.jobIndicator.occupationLimited) {
						dispatch(setOccupationLimited(data.jobIndicator.occupationLimited));
						if (
							data.jobIndicator.occupationLimited === 'Befristet' &&
							data.jobIndicator.occupationLimitedMonth &&
							data.jobIndicator.occupationLimitedYear
						) {
							dispatch(setOccupationLimitedMonth(data.jobIndicator.occupationLimitedMonth));
							dispatch(setOccupationLimitedYear(data.jobIndicator.occupationLimitedYear));
						}
					}
				}

				if (data.iban) {
					dispatch(setReferenceIban(data.iban));
				}

				if (data.existingOldIban) {
					dispatch(setExistingOldIban(data.existingOldIban));
				}

				if (data.additionalData) {
					if (data.additionalData.amount) {
						dispatch(updateAmount(data.additionalData.amount));
						dispatch(setAmount(data.additionalData.amount));
					}
					if (data.additionalData.runtime) {
						dispatch(updateRuntime(data.additionalData.runtime));
						dispatch(setRuntime(data.additionalData.runtime));
					}
					if (data.additionalData.paymentType) {
						dispatch(setPaymentType(data.additionalData.paymentType));
					}
					if (data.additionalData.redemptionAmount) {
						dispatch(setExternalCredits([{
							iban: C.DUMMY_IBAN,
							remainingBalance: data.additionalData.redemptionAmount
						}]));
					}
					if (data.additionalData.conditionSet) {
						dispatch(updateCondition(data.additionalData.conditionSet));
					}
					if (data.additionalData.earnings) {
						dispatch(onUpdateIncome(data.additionalData.earnings));
					}
					if (data.additionalData.additionalIncome) {
						dispatch(onUpdateAdditionalIncome(data.additionalData.additionalIncome));
					}
				}

				if (data.requestedBestCardLimit) {
					dispatch(setRequestedLimit(data.requestedBestCardLimit));
				}

				if (data.bcoResult) {
					dispatch(updateBcoResults(data.bcoResult));
				}

				if (data.hasSecondApplicant) {
					dispatch(onUpdateHasSecondApplicant(data.hasSecondApplicant));
				}

				if (data.secondBorrower) {
					dispatch(onUpdateHasSecondApplicant(data.secondBorrower));
				}

				if (data.capsUploadDone) {
					dispatch(updateCapsUploadDone(data.capsUploadDone));
				}

				if (data.webIdStarted) {
					dispatch(updateWebIdStarted(data.webIdStarted));
				}

				if (data.webIdFinished) {
					dispatch(updateWebIdFinished(data.webIdFinished));
				}

				if (data.isRequestFromCaps) {
					dispatch(updateIsRequestFromCaps(data.isRequestFromCaps));
				}

				if (data.skipLegiLP1 || data.skipLegiLP2) {
					dispatch(updateSkipLegi({
						skipLegiLP1: !!data.skipLegiLP1,
						skipLegiLP2: !!data.skipLegiLP2
					}));
				}
			}
		} catch (e) {
			console.error(e);
		}
	};

	return {
		getApiBaseURL,
		startApiCall,
		stopApiCall,
		checkUnauthorized,
		addUserDataToStore
	};
};
