import React, { useEffect } from 'react';
import C from 'constants/Constants';
import { C as Protect } from 'constants/enums/Protect';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useInsuranceUtils } from 'utils/insurance/insurance';

import { Title, Text } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import Overview from 'components/Pages/Insurance/Overview/Overview';
import ProtectBody from 'components/Pages/Insurance/Protect/Protect';
import SvgScales from 'components/Atoms/SVG/Icons/SvgScales';

import { useSelector } from 'react-redux';
import Divider from '../../Atoms/Divider/Divider';

const Insurance = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid, ecommerceAddToCart, ecommerceRemoveFromCart } = useTrackingUtils();
	const { hasEmptyFieldsError, focusFirstErrorInput } = useValidationUtils();
	const { showInsurancePage } = useInsuranceUtils();

	const currentFlow = getCurrentFlow();

	const storeProtect = useSelector((state => state.insurance.protect));

	useEffect(() => {
		if (!showInsurancePage()) {
			goToPage(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
		}
	}, []);

	const clearErrors = () => {};

	const validateFields = async () => {
		let hasEmptyFields = false;

		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();

		const protectFormatted = storeProtect ? storeProtect.toLowerCase() : null;
		if (storeProtect && (protectFormatted === Protect.STANDARD || protectFormatted === Protect.PREMIUM || protectFormatted === Protect.BASIC)) {
			ecommerceAddToCart(true, 'protect');
		} else {
			ecommerceRemoveFromCart('protect');
		}

		goToPage(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const onPrevButton = () => {
		goToPage(currentFlow + C.ROUTES.EXTERNAL_CREDIT);
	};

	return (
		<>
			<Title mb>{m('pages.insurance.title', 'global')}</Title>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={6}
				onPrevButton={onPrevButton}
				hideSubmitDividerMobile
			>
				<Overview />
				<Text mb>
					<SvgScales color="#ec0000" />
					<strong>{m('pages.insurance.protect.title', 'global')}<sup>*</sup></strong>
				</Text>
				<Text mb>{m('pages.insurance.protect.subtitle', 'global')}</Text>
				<ProtectBody />
			</Form>
			<Divider />
			<Text mb size="s">{m('pages.insurance.protect.hint', 'global')}</Text>
		</>
	);
};
export default Insurance;
