import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import C from 'constants/Constants';
import { C as Autocomplete } from 'constants/enums/Autocomplete';
import { CHAR_SPACE_DASH_INPUT } from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useItemsUtils } from 'utils/items/items';

import { Col, Row } from 'components/Atoms/Grid';
import Form from 'components/Molecules/Form/Form';
import { Link, Text, Title } from 'components/Atoms/Text';
import { TextInput, DropdownInput } from 'components/Atoms/Form';
import NeededUtilsAndDocuments from 'components/Atoms/Partials/NeededUtilsAndDocuments/NeededUtilsAndDocuments';
import Divider from 'components/Atoms/Divider/Divider';
import SvgCircleAccountUser from 'components/Atoms/SVG/Icons/SvgCircleAccountUser';
import SvgMobile from 'components/Atoms/SVG/Icons/SvgMobile';
import SvgCards from 'components/Atoms/SVG/Icons/SvgCards';
import SvgIllustrationOnlineShopping from 'components/Atoms/SVG/Illustrations/SvgIllustrationOnlineShopping';
import SvgCamera from 'components/Atoms/SVG/Icons/SvgCamera';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import { setSidebarActive } from 'store/App/actions';
import { setSalutation, setFirstName, setLastName } from 'store/Name/actions';

const Name = () => {
	const { getCurrentFlow, isCreditSubType, isCreditCardSubType, isCreditOrCreditCardSubType, isStaticSubType } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput, hasCompanyInNameError, hasAllUpperOrLowercaseError } = useValidationUtils();
	const { getSalutationDropdownItems } = useItemsUtils();

	const salutationItems = getSalutationDropdownItems();

	const location = useLocation();
	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeSalutation = useSelector((state => state.name.salutation));
	const storeFirstName = useSelector((state => state.name.firstName));
	const storeLastName = useSelector((state => state.name.lastName));

	const storeInitialPageLoad = useSelector((state => state.app.initialPageLoad));

	const [salutationError, setSalutationError] = useState(null);
	const [firstNameError, setFirstNameError] = useState(null);
	const [lastNameError, setLastNameError] = useState(null);

	const neededUtilsAndDocumentsItems = [
		{ icon: <SvgCircleAccountUser />, text: m('pages.name.neededUtilsAndDocuments.items.passport', 'global') },
		{ icon: <SvgMobile />, text: m('pages.name.neededUtilsAndDocuments.items.mobileNumber', 'global') }
	];
	if (isCreditSubType()) {
		neededUtilsAndDocumentsItems.push({ icon: <SvgCards />, text: m('name.neededUtilsAndDocuments.items.iban', currentFlow) });
	}
	if (
		isCreditCardSubType(null, [C.FRONTEND.VISACARD] ) ||
		isStaticSubType(null, [C.FRONTEND.GIRO])
	) {
		neededUtilsAndDocumentsItems.push({ icon: <SvgCards />, text: m('pages.name.neededUtilsAndDocuments.items.iban', 'global') });
	}
	if (currentFlow === C.FRONTEND.VISACARD) {
		neededUtilsAndDocumentsItems.push({ icon: <SvgCamera />, text: m('pages.name.neededUtilsAndDocuments.items.camera', 'global') });
	}

	useEffect(() => {
		if (isCreditSubType() && storeInitialPageLoad) {
			let search = new URLSearchParams(location.search);
			let amount = search.get('amount');
			let runtime = search.get('runtime');
			if (!amount || !runtime) {
				dispatch(setSidebarActive(true));
			}
		}
	}, []);

	const clearErrors = () => {
		setSalutationError(null);
		setFirstNameError(null);
		setLastNameError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let hasCompanyError = false;
		let hasUpperLowercaseError = false;

		if (isEmpty(storeSalutation)) {
			setSalutationError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
	}
		if (isEmpty(storeFirstName)) {
			setFirstNameError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		} else if (hasAllUpperOrLowercaseError(storeFirstName)) {
			setFirstNameError(m('validation.error.name.upperLowerCaseFirstName', 'fields'));
			hasUpperLowercaseError = true;
		}
		if (isEmpty(storeLastName)) {
			setLastNameError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		} else if (hasCompanyInNameError(storeLastName)) {
			setLastNameError(m('validation.error.name.isCompany', 'fields'));
			hasCompanyError = true;
		} else if (hasAllUpperOrLowercaseError(storeLastName)) {
			setLastNameError(m('validation.error.name.upperLowerCaseLastName', 'fields'));
			hasUpperLowercaseError = true;
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields && !hasCompanyError && !hasUpperLowercaseError;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.BIRTHDATE);
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const autofill = () => {
		dispatch(setSalutation('MALE'));
		dispatch(setFirstName('Max'));
		dispatch(setLastName('Happy'));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Title mb>{m('pages.name.title', 'global')}</Title>
			<Text size="l" mb>{m('pages.name.subtitle', 'global')}</Text>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={6}
			>
				<Row>
					<Col xs={12} sm={4}>
						<DropdownInput
							value={storeSalutation}
							setValue={(value) => { dispatch(setSalutation(value)); }}
							items={salutationItems}
							label={m('salutation.label', 'fields')}
							hasError={!!salutationError}
							message={salutationError}
							tabindex={2}
							testId="salutation"
						/>
					</Col>
					<Col xs={12} sm={4}>
						<TextInput
							value={storeFirstName}
							setValue={(value) => { dispatch(setFirstName(value)); }}
							label={m('firstName.label', 'fields')}
							maxLength={50}
							hasError={!!firstNameError}
							message={firstNameError}
							autoComplete={Autocomplete.GIVEN_NAME}
							regex={CHAR_SPACE_DASH_INPUT}
							tabindex={3}
							testId="first-name"
						/>
					</Col>
					<Col xs={12} sm={4}>
						<TextInput
							value={storeLastName}
							setValue={(value) => { dispatch(setLastName(value)); }}
							label={m('lastName.label', 'fields')}
							maxLength={50}
							hasError={!!lastNameError}
							message={lastNameError}
							autoComplete={Autocomplete.FAMILY_NAME}
							regex={CHAR_SPACE_DASH_INPUT}
							tabindex={4}
							testId="last-name"
						/>
					</Col>
				</Row>
				<Text>
					{m('pages.name.alreadyKnown', 'global')}&nbsp;
					<Link
						onClick={() => { goToPage(currentFlow + C.ROUTES.LOGIN); }}
						tabindex={5}
					>
						{m('pages.name.loginHere', 'global')}
					</Link>
				</Text>
			</Form>

			{(
				isCreditOrCreditCardSubType() ||
				isStaticSubType()
			) && (
				<>
					<Divider noLine noMarginBottom />
					<NeededUtilsAndDocuments
						title={m('pages.name.neededUtilsAndDocuments.title', 'global')}
						items={neededUtilsAndDocumentsItems}
						image={<SvgIllustrationOnlineShopping />}
					/>
				</>
			)}
		</>
	);
};
export default Name;
