import React, { forwardRef, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import de from 'date-fns/locale/de';

import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';
import HelperText from 'components/Atoms/Form/HelperText/HelperText';
import SvgCalendar from 'components/Atoms/SVG/Icons/SvgCalendar';

import textInputStyles from '../TextInput/TextInput.module.scss';
import styles from './DateInput.module.scss';

export const DateInput = forwardRef((props, ref) => {
	const [id] = useState(uuid());
	const inputRef = ref ? ref : useRef(null);
	const inputWrapperRef = useRef(null);

	const onChange = (date) => {
		props.setValue(moment(date).format(props.showMonthYearPicker ? 'YYYY-MM' : 'YYYY-MM-DD'));
	};

	return (
		<div className={textInputStyles.wrapper}>
			<div className={textInputStyles.labelWrapper}>
				<label htmlFor={id} className={textInputStyles.label}>
					{props.label}
				</label>
				{props.tooltip && (
					<div className={textInputStyles.tooltip}>
						<TooltipIcon text={props.tooltip} />
					</div>
				)}
			</div>
			<div className={textInputStyles.inputWrapper} ref={inputWrapperRef}>
				<DatePicker
					ref={inputRef}
					id={id}
					className={classNames(
						textInputStyles.input,
						textInputStyles.hasSuffix,
						props.hasError ? textInputStyles.error : null,
						props.hasError ? 'input--error' : null
					)}
					selected={
						props.value
							? moment(
									props.value,
									props.showMonthYearPicker ? 'YYYY-MM' : 'YYYY-MM-DD'
							  ).toDate()
							: null
					}
					onChange={(date) => onChange(date)}
					dateFormat={props.showMonthYearPicker ? 'MM/yyyy' : 'dd.MM.yyyy'}
					maxDate={props.maxDate ?? null}
					minDate={props.minDate ?? null}
					showMonthYearPicker={props.showMonthYearPicker}
					showFullMonthYearPicker
					locale={de}
					calendarStartDay={1}
					disabled={props.disabled}
					showYearDropdown
					dateFormatCalendar="MMMM"
					yearDropdownItemNumber={15}
					scrollableYearDropdown
					tabIndex={props.tabindex}
					data-testid={props.testId ?? null}
				/>
				<div
					className={classNames(textInputStyles.suffix, styles.suffix)}
					onClick={() => {
						props.setValue(null);
					}}
				>
					<SvgCalendar />
				</div>
			</div>
			<HelperText
				type={props.hasError ? 'error' : 'default'}
				message={props.message}
				hasIcon
			/>
		</div>
	);
});

DateInput.propTypes = {
	value: PropTypes.string,
	setValue: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	disabled: PropTypes.bool,
	showMonthYearPicker: PropTypes.bool,
	maxDate: PropTypes.any,
	minDate: PropTypes.any,
	hasError: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tabindex: PropTypes.number,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	testId: PropTypes.string
};

export default DateInput;
