import moment from 'moment';

export const useDateUtils = () => {
	const getMaxDaysOfMonth = (month) => {
		let maxDays = 31;
		if (month === 2) {
			maxDays = 29;
		} else if (month === 4 || month === 6 || month === 9 || month === 11) {
			maxDays = 30;
		}
		return maxDays;
	};

	const getLastDayOfMonth = (year, month) => {
		const intDate = new Date(parseInt(year), parseInt(month), 1);
		const date = new Date(intDate - 1);
		return date.getDate();
	};

	const monthDiff = (dateFrom, dateTo) => {
		return (
			dateTo.getMonth() -
			dateFrom.getMonth() +
			12 * (dateTo.getFullYear() - dateFrom.getFullYear())
		);
	};

	const formatDateForApi = (day, month, year, separator = '-', reverse = false) => {
		let formattedDay = day;
		if (formattedDay.length === 1) {
			formattedDay = '0' + formattedDay;
		}

		let formattedMonth = month;
		if (formattedMonth.length === 1) {
			formattedMonth = '0' + formattedMonth;
		}

		if (reverse) {
			return formattedDay + separator + formattedMonth + separator + year;
		} else {
			return year + separator + formattedMonth + separator + formattedDay;
		}
	};

	const formatDateFromApi = (dateString) => {
		let dateArray = dateString.trim().split('-');

		return {
			day: dateArray[2],
			month: dateArray[1],
			year: dateArray[0]
		};
	};

	const formatFullDate = (date) => {
		if (date) {
			let dateReplaced = date.replace(/\//g, '.');
			dateReplaced = dateReplaced.replace(/-/g, '.');
			return dateReplaced;
		} else {
			return undefined;
		}
	};

	const getOccupationStartDay = (year, month) => {
		return year !== '' && month !== ''
			? year + '-' + month.padStart(2, 0) + '-01'
			: '';
	};

	const getOccupationLimitedStartDay = (year, month) => {
		return year !== '' && month !== ''
			? year + '-' + month.padStart(2, 0) + '-' + getLastDayOfMonth(year, month)
			: '';
	};

	const getAge = (birthdate) => {
		if (birthdate) {
			let birthdateDate = moment(birthdate, 'YYYY-MM-DD');
			return moment().diff(birthdateDate, 'years');
		} else {
			return 0;
		}
	};

	const getAgeWithRuntime = (birthdate, runtimeYears) => {
		return getAge(birthdate) + runtimeYears;
	};

	const formatDateForInput = (event, prevValue, ref, successCb) => {
		const valueBeforeChange = event.target.value;
		const cursorPosition = event.target.selectionStart;

		let formattedValue = valueBeforeChange;

		if (valueBeforeChange && valueBeforeChange.length > 0) {
			if (prevValue && prevValue > valueBeforeChange) {
				// Skip space on Backspace
				if (formattedValue.charAt(cursorPosition - 1) === '.') {
					formattedValue = formattedValue.slice(0, -1).slice(0, -1);
				}
			} else {
				// set . automatically
				formattedValue = formatFullDate(formattedValue);
				if (formattedValue.length === 2 || formattedValue.length === 5) {
					formattedValue += '.';
				}
			}
		}

		successCb(formattedValue);
	};

	return {
		getMaxDaysOfMonth,
		getLastDayOfMonth,
		monthDiff,
		formatDateForApi,
		formatDateFromApi,
		formatFullDate,
		getOccupationStartDay,
		getOccupationLimitedStartDay,
		getAge,
		getAgeWithRuntime,
		formatDateForInput
	};
};
