import { useUtils } from 'utils/utils';
import { useMemo } from 'react';
import C from 'constants/Constants';
import { useSelector } from 'react-redux';

export const useFlowUtils = () => {
	const { getCurrentFlow } = useUtils();

	const currentFlow = getCurrentFlow();

	const storeUid = useSelector((state => state.app.uid));
	const storeSanc = useSelector((state => state.app.sanc));

	const auxMoneySanc = [
		'9300190100',
		'9300520200'
	];

	const ergoSanc = [
		'9300120100',
		'9300370200',
		'9300530200',
		'9300540200',
		'9300540300',
		'9300540400',
		'9300540500',
		'9300540600',
		'9300540700',
		'9300540800',
		'9300540900'
	];

	const isCreditAuxMoneySanc = useMemo(() =>
			currentFlow === C.FRONTEND.CREDIT
			&& (auxMoneySanc.includes(storeSanc)),
		[currentFlow, storeSanc]);

	const isCreditErgoSanc = useMemo(() =>
			currentFlow === C.FRONTEND.CREDIT
			&& (ergoSanc.includes(storeSanc)),
		[currentFlow, storeSanc]);

	const isGiroErgoUid = useMemo(() =>
			currentFlow === C.FRONTEND.GIRO
			&& storeUid === 'ergo',
		[currentFlow, storeUid]);

	const isGmkHyundai = useMemo(() =>
			currentFlow === C.FRONTEND.GMK
			&& storeUid === 'hyundai',
		[currentFlow, storeUid]);

	const gmkHyundaiMsgPrefix = useMemo(() => isGmkHyundai ? 'hyundai.' : '', [isGmkHyundai]);

	const isGmkPartner = useMemo(() => isGmkHyundai, [isGmkHyundai]);

	const hideAdvertisingCheckbox = useMemo(
		() => isCreditErgoSanc
			|| isCreditAuxMoneySanc
			|| isGiroErgoUid
			|| isGmkHyundai,
		[isCreditErgoSanc, isCreditAuxMoneySanc, isGiroErgoUid, isGmkHyundai]
	);

	return {
		auxMoneySanc,
		ergoSanc,
		isCreditAuxMoneySanc,
		isCreditErgoSanc,
		isGiroErgoUid,
		isGmkHyundai,
		gmkHyundaiMsgPrefix,
		isGmkPartner,
		hideAdvertisingCheckbox
	};
};
